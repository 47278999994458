import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 528.000000 492.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,492.000000) scale(0.100000,-0.100000)">
          
          <path d="M2798 4111 c-76 -77 -79 -78 -137 -84 -91 -10 -147 13 -232 91 -76
70 -78 70 -120 10 l-21 -28 74 -68 c257 -236 252 -232 270 -232 21 1 82 52
215 180 l126 122 -44 44 c-24 24 -46 44 -49 44 -3 0 -40 -36 -82 -79z"/>
<path d="M2110 3935 l-34 -35 109 -107 c61 -58 127 -122 147 -141 l37 -35 40
34 c21 19 40 39 40 46 1 6 -11 21 -26 33 -16 12 -44 36 -63 54 -19 17 -73 67
-119 109 -46 42 -87 77 -90 77 -4 0 -22 -16 -41 -35z"/>
<path d="M2968 3829 c-70 -72 -128 -134 -128 -139 0 -5 18 -22 40 -40 l39 -31
92 88 c123 119 169 166 169 176 0 17 -53 77 -68 77 -9 0 -73 -59 -144 -131z"/>
<path d="M1901 3716 l-34 -35 63 -67 c75 -81 93 -119 94 -204 1 -82 -16 -120
-90 -193 -62 -61 -62 -73 -4 -97 28 -11 32 -9 91 41 92 80 234 221 242 242 4
10 0 31 -8 46 -15 30 -288 294 -308 299 -7 1 -28 -13 -46 -32z"/>
<path d="M3275 3714 c-22 -20 -80 -73 -130 -118 -140 -126 -145 -132 -145
-162 0 -22 30 -58 154 -182 l153 -155 36 39 c39 43 39 46 -19 109 -20 22 -48
65 -62 95 -49 104 -29 182 74 295 l55 60 -31 28 c-17 15 -34 27 -38 27 -4 0
-25 -16 -47 -36z"/>
<path d="M2573 3668 c-95 -81 -223 -220 -223 -242 0 -27 204 -229 257 -254 45
-22 65 -10 196 118 78 76 107 112 107 129 0 17 -20 44 -63 85 -139 135 -208
196 -222 196 -8 0 -31 -15 -52 -32z m122 -146 c33 -29 45 -59 45 -108 0 -41
-5 -51 -39 -85 -33 -34 -45 -39 -82 -39 -69 1 -109 45 -109 119 0 59 15 88 57
110 47 25 102 26 128 3z"/>
<path d="M2837 3193 l-38 -34 33 -37 c41 -45 240 -220 259 -227 8 -2 30 8 49
24 l34 29 -100 98 c-91 91 -173 166 -192 178 -4 2 -24 -11 -45 -31z"/>
<path d="M2262 3133 c-106 -107 -172 -179 -172 -189 0 -4 17 -22 38 -39 l37
-33 100 97 c54 53 115 113 133 133 l34 38 -32 40 c-17 22 -36 40 -41 40 -6 0
-49 -39 -97 -87z"/>
<path d="M2440 2902 l-160 -157 23 -25 c52 -58 50 -58 128 14 83 77 147 103
232 92 55 -6 95 -29 172 -100 28 -25 52 -46 56 -46 3 0 21 16 40 36 l34 35
-159 155 c-116 113 -165 154 -183 154 -18 0 -66 -41 -183 -158z"/>
<path d="M3429 2493 c-7 -16 -15 -31 -17 -35 -7 -15 25 -8 47 11 13 11 19 25
15 35 -9 24 -29 19 -45 -11z"/>
<path d="M3486 2451 c-3 -5 1 -25 9 -45 8 -19 15 -46 15 -59 0 -15 9 -29 25
-37 14 -7 25 -17 25 -22 0 -4 12 -8 28 -8 l27 0 -32 20 c-36 21 -44 33 -15 23
15 -6 15 -5 3 10 -12 15 -10 19 10 36 15 12 28 16 35 10 23 -19 107 -60 112
-54 3 3 1 5 -4 5 -5 0 -33 16 -63 35 -29 19 -57 33 -61 30 -5 -3 -10 -2 -12 2
-5 12 -76 63 -87 63 -5 0 -12 -4 -15 -9z"/>
<path d="M3390 2410 c-21 -21 -27 -50 -10 -50 6 0 10 4 10 8 0 12 32 11 48 -1
9 -7 11 -6 6 6 -3 9 -15 18 -27 20 -19 4 -19 4 4 6 14 0 30 -4 37 -11 9 -9 12
-9 12 -1 0 12 -38 43 -52 43 -5 0 -17 -9 -28 -20z"/>
<path d="M1475 2230 c-127 -23 -216 -78 -263 -165 -26 -46 -22 -159 7 -214 29
-56 50 -75 126 -111 41 -20 99 -36 185 -50 135 -22 211 -50 235 -86 24 -37 19
-98 -11 -134 -45 -52 -78 -64 -184 -65 -115 0 -178 24 -240 92 l-41 44 -60
-52 c-32 -28 -59 -55 -59 -61 0 -5 26 -36 58 -67 63 -63 129 -95 237 -113 117
-19 279 7 365 61 88 53 136 152 127 258 -7 90 -39 149 -107 195 -66 44 -91 52
-250 78 -186 32 -230 55 -230 122 0 73 72 118 190 118 74 0 119 -14 180 -55
29 -19 55 -35 59 -35 12 1 101 87 101 98 0 15 -43 57 -90 88 -70 46 -231 72
-335 54z"/>
<path d="M2410 2226 c-112 -25 -194 -88 -247 -187 -25 -49 -28 -64 -28 -149 1
-79 4 -102 24 -139 34 -66 107 -137 170 -168 50 -25 67 -28 156 -28 91 0 105
3 158 30 71 36 125 89 163 158 26 47 29 61 29 147 -1 110 -18 159 -81 226 -94
99 -218 139 -344 110z m147 -171 c99 -41 138 -143 94 -241 -59 -131 -265 -133
-331 -4 -32 63 -20 149 28 204 45 50 141 69 209 41z"/>
<path d="M3080 1740 l0 -480 280 0 280 0 0 80 0 80 -190 0 -190 0 -2 398 -3
397 -87 3 -88 3 0 -481z"/>
<path d="M3880 1740 l0 -480 90 0 90 0 -2 478 -3 477 -87 3 -88 3 0 -481z"/>
<path d="M3505 2170 c-13 -5 -14 -9 -5 -9 8 0 24 4 35 9 13 5 14 9 5 9 -8 0
-24 -4 -35 -9z"/>
<path d="M3605 2170 c3 -5 0 -8 -7 -5 -7 3 -26 1 -43 -4 -27 -8 -25 -9 20 -9
28 -1 59 3 69 7 18 7 18 8 -5 14 -33 9 -41 9 -34 -3z"/>
<path d="M3688 2173 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2331 1404 c-83 -42 -151 -78 -151 -80 0 -2 11 -22 25 -43 l24 -39
93 30 c84 27 102 30 183 25 59 -3 115 -14 162 -30 78 -28 77 -28 107 30 l16
32 -137 69 c-76 38 -146 71 -155 75 -11 4 -74 -22 -167 -69z"/>
<path d="M2784 1000 c-30 -12 -54 -49 -54 -81 0 -31 35 -64 79 -74 39 -8 65
-38 56 -64 -9 -29 -52 -38 -88 -18 -55 32 -84 13 -43 -28 21 -20 35 -25 79
-25 69 0 97 23 97 81 0 49 -29 82 -88 100 -49 14 -58 31 -31 55 18 16 25 16
65 6 25 -7 50 -10 55 -7 18 12 -11 44 -49 55 -43 12 -50 12 -78 0z"/>
<path d="M4413 1003 c-30 -6 -63 -49 -63 -83 0 -34 24 -56 83 -76 26 -9 47
-20 47 -24 0 -5 3 -15 6 -23 14 -37 -69 -65 -96 -32 -14 17 -46 20 -55 5 -3
-5 6 -22 20 -36 24 -24 32 -26 86 -22 69 5 88 22 89 81 0 47 -30 80 -89 98
-32 9 -41 17 -41 35 0 33 51 46 89 22 10 -6 26 -8 35 -5 54 21 -44 73 -111 60z"/>
<path d="M977 993 c-4 -3 -7 -69 -7 -145 l0 -138 71 0 c106 0 151 31 170 115
10 47 9 57 -7 91 -29 60 -74 84 -154 84 -37 0 -70 -3 -73 -7z m162 -62 c30
-26 37 -80 16 -124 -18 -38 -24 -41 -92 -52 l-43 -7 0 101 0 101 48 0 c34 0
56 -6 71 -19z"/>
<path d="M1260 855 c0 -135 1 -145 19 -145 15 0 19 11 25 77 3 42 6 107 6 145
0 66 -1 68 -25 68 l-25 0 0 -145z"/>
<path d="M1423 980 c-42 -25 -63 -68 -63 -125 0 -61 22 -100 70 -125 47 -24
126 -26 158 -4 17 12 22 25 22 62 0 56 -6 67 -43 76 -39 9 -67 -1 -67 -25 0
-14 7 -19 29 -19 20 0 30 -6 34 -19 8 -31 -9 -46 -52 -46 -49 0 -92 30 -104
72 -21 73 55 148 126 124 46 -16 67 -14 67 8 0 41 -121 55 -177 21z"/>
<path d="M1660 855 c0 -134 1 -145 19 -145 16 0 19 11 25 92 3 50 6 115 6 145
0 50 -1 53 -25 53 l-25 0 0 -145z"/>
<path d="M1750 981 c0 -16 8 -20 43 -23 l42 -3 3 -123 c3 -121 3 -123 25 -120
21 3 22 7 22 123 l0 120 25 1 c53 1 60 4 60 24 0 19 -7 20 -110 20 -102 0
-110 -1 -110 -19z"/>
<path d="M2098 978 c-28 -44 -108 -231 -108 -252 0 -30 36 -17 53 19 l17 35
70 0 70 0 14 -35 c10 -24 22 -35 35 -35 12 0 21 6 21 13 0 20 -75 190 -105
237 -29 45 -46 50 -67 18z m57 -98 c10 -22 16 -43 13 -46 -3 -3 -24 -4 -45 -2
l-40 3 18 43 c10 23 22 42 27 42 5 0 17 -18 27 -40z"/>
<path d="M2312 858 l3 -143 83 -3 c76 -3 82 -1 82 17 0 18 -8 20 -62 23 l-63
3 2 123 2 122 -25 0 -25 0 3 -142z"/>
<path d="M3230 993 c-27 -10 -30 -26 -30 -155 0 -121 1 -128 20 -128 18 0 20
7 20 74 0 40 3 94 6 120 l7 46 42 0 c56 0 74 -20 51 -55 -9 -14 -25 -25 -35
-25 -60 0 -66 -28 -15 -72 19 -17 42 -44 50 -59 16 -31 54 -40 54 -13 0 16
-36 73 -54 86 -5 4 4 19 22 36 49 46 42 110 -15 140 -22 12 -96 15 -123 5z"/>
<path d="M3443 984 c-7 -19 -4 -28 61 -169 62 -136 69 -136 135 7 61 130 74
182 46 177 -12 -3 -33 -37 -61 -99 -24 -52 -47 -96 -52 -98 -5 -1 -29 43 -55
98 -46 98 -61 116 -74 84z"/>
<path d="M3747 993 c-4 -3 -7 -69 -7 -145 0 -128 1 -138 18 -138 17 0 20 12
25 131 3 72 3 137 0 145 -5 15 -25 19 -36 7z"/>
<path d="M3911 982 c-46 -25 -71 -74 -71 -141 0 -49 3 -57 39 -92 35 -35 44
-39 89 -39 68 0 102 14 102 41 0 18 -4 20 -27 15 -64 -16 -79 -17 -106 -7 -15
6 -34 25 -44 45 -14 30 -15 41 -4 76 15 52 49 74 98 66 71 -12 78 -11 78 4 0
18 -60 50 -95 50 -14 0 -41 -8 -59 -18z"/>
<path d="M2977 983 c-4 -3 -7 -66 -7 -140 l0 -133 91 0 c81 0 90 2 87 18 -2
13 -17 18 -68 22 -65 5 -65 5 -68 37 -3 31 -3 32 45 35 40 3 48 6 48 23 0 16
-8 21 -45 25 -42 5 -45 7 -48 38 l-3 32 64 0 c66 0 89 14 64 39 -12 12 -149
16 -160 4z"/>
<path d="M4127 984 c-4 -4 -7 -67 -7 -141 l0 -133 91 0 c81 0 90 2 87 18 -3
14 -16 18 -71 20 l-67 3 0 34 c0 34 1 35 38 35 45 0 67 13 59 34 -5 11 -19 16
-52 16 -45 0 -45 0 -45 35 l0 35 66 0 c62 0 65 1 62 23 -3 20 -9 22 -78 25
-42 1 -79 0 -83 -4z"/>
<path d="M0 10 c0 -7 887 -10 2640 -10 1753 0 2640 3 2640 10 0 7 -887 10
-2640 10 -1753 0 -2640 -3 -2640 -10z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
